import React, { useState } from 'react'
import {
  Alert, Chip, IconButton, Snackbar,
  TextField,
} from '@mui/material'
import {
  Cancel, CheckCircle, Delete, Edit,
} from '@mui/icons-material'
import {
  GridColumns, GridRenderCellParams, GridRowEditStartParams, GridRowEditStopParams, GridRowModel, GridRowModes, GridRowModesModel,
} from '@mui/x-data-grid'
import moment from 'moment'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  COLUMN_WIDTH, EVENT, MAINTENANCE_STATUS_BADGES, MAINTENANCE_STATUS,
} from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton,
  filterDateTimeOps,
  filterStringOps,
  fliterSingleSelectOps,
} from '../common/DataGrid'
import DeleteMaintenanceTaskModal from './DeleteMaintenanceTaskModal'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  show: boolean
  refresh: boolean
  onRefresh: (refreshGrid: boolean) => void
}

function getStatusBadge(status: typeof MAINTENANCE_STATUS[keyof typeof MAINTENANCE_STATUS]) {
  const { color, label } = MAINTENANCE_STATUS_BADGES[status]
  return <Chip label={label} color={color} size="small" />
}

function MaintenanceGrid(props: Props) {
  const { show, refresh, onRefresh } = props

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null)
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
  const [errorMessageSnackbar, setErrorMessageSnackbar] = useState('')
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false)
  const [successMessageSnackbar, setSuccessMessageSnackbar] = useState('')
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
  const [originalRowsData, setOriginalRowsData] = useState<{ [id: string]: GridRowModel }>({})
  const [editedRowData, setEditedRowData] = useState<GridRowModel | null>(null)

  const openDeleteModal = (taskId: number) => {
    setSelectedTaskId(taskId)
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
    setSelectedTaskId(null)
  }

  const handleCloseErrorSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') return
    setShowErrorSnackbar(false)
    setErrorMessageSnackbar('')
  }

  const handleCloseSuccessSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    setShowSuccessSnackbar(false)
    setSuccessMessageSnackbar('')
  }

  const handleRowEditStart = (
    params: GridRowEditStartParams,
  ) => {
    if (params.reason === 'enterKeyDown') {
      setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } })
      setOriginalRowsData((prev) => ({
        ...prev,
        [params.id]: { mode: 'edit' },
      }))
    }
  }

  const handleRowEditStop = (
    params: GridRowEditStopParams,
  ) => {
    if (params.reason && ['escapeKeyDown', 'shiftTabKeyDown'].includes(params.reason)) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      })
    }
  }

  const handleEditButtonClick = (rows: GridRowModel) => {
    setRowModesModel({ ...rowModesModel, [rows.id]: { mode: GridRowModes.Edit } })
    setOriginalRowsData((prev) => ({
      ...prev,
      [rows.id]: rows,
    }))
    setEditedRowData({ ...rows })
  }

  const processRowUpdate = async (newRow: GridRowModel) => {
    const originalRow = originalRowsData[newRow.id]
    if (!editedRowData) return originalRow
    const isModified = Object.keys(editedRowData).some((key) => editedRowData[key] !== originalRow[key])

    if (!isModified) {
      setShowErrorSnackbar(true)
      setErrorMessageSnackbar('No changes data detected.')
      return originalRow
    }

    if (!editedRowData.description || !editedRowData.startTime || !editedRowData.endTime) {
      setShowErrorSnackbar(true)
      setErrorMessageSnackbar('Description, Start Time, or End Time must not be empty.')
      return originalRow
    }

    if (originalRow.deletedAt != null) {
      setShowErrorSnackbar(true)
      setErrorMessageSnackbar('Deleted data can not be edit')
      return originalRow
    }

    try {
      await fetchAdmin({
        path: `maintenance/${editedRowData.id}`,
        method: 'PUT',
        body: {
          description: editedRowData.description,
          startTime: editedRowData.startTime,
          endTime: editedRowData.endTime,
        },
      })
      setShowSuccessSnackbar(true)
      setSuccessMessageSnackbar('Changes saved successfully!')
      return editedRowData
    } catch (error) {
      return originalRow
    }
  }

  const handleSave = async (rowId: string) => {
    setRowModesModel({ ...rowModesModel, [rowId]: { mode: GridRowModes.View } })
  }

  const handleCancel = (rowId: string) => {
    setRowModesModel({
      ...rowModesModel,
      [rowId]: { mode: GridRowModes.View, ignoreModifications: true },
    })
    setEditedRowData(null)
  }

  const handleEditChange = (field: string, value: any) => {
    if (editedRowData) {
      setEditedRowData({ ...editedRowData, [field]: value })
    }
  }

  return (
    <>
      <DataGrid
        show={show}
        refresh={refresh}
        path="maintenances"
        columns={columns(openDeleteModal, handleEditButtonClick, handleSave, handleCancel, rowModesModel, editedRowData, handleEditChange)}
        onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_MAINTENANCE_DETAILS, row.row)}
        searchColumns={[
          { label: 'Description', field: 'description' },
        ]}
        initialSortItem={{ field: 'startTime', sort: 'desc' }}
        setCustomRowClassName={(params) => {
          const now = new Date()
          const startTime = new Date(params.row.startTime)
          const endTime = new Date(params.row.endTime)
          if (params.row.deletedAt !== null) return 'row-color-error'
          if (now < startTime) return 'row-color-info'
          if (now <= endTime) return ''
          return 'row-color-completed'
        }}
        setCustomRowDescriptions={[
          { rowClassName: 'row-color-info', description: 'Current' },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="row"
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        rowModesModel={rowModesModel}
        processRowUpdate={processRowUpdate}
        onCellKeyDown={(params, event) => {
          if (event.code === 'Space' && rowModesModel[params.row.id]?.mode === GridRowModes.Edit) {
            event.stopPropagation()
          }
        }}
      />

      {selectedTaskId && (
        <DeleteMaintenanceTaskModal
          taskId={selectedTaskId}
          open={deleteModalOpen}
          handleClose={(actionTriggered: boolean) => {
            closeDeleteModal()
            if (actionTriggered) {
              onRefresh(true)
            }
          }}
        />
      )}

      {/* snackbar for error */}
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessageSnackbar}
        </Alert>
      </Snackbar>

      {/* snackbar for successful save */}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessageSnackbar}
        </Alert>
      </Snackbar>
    </>
  )
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_MAINTENANCE_DETAILS })

const columns = (
  openDeleteModal: (taskId: number) => void,
  handleEdit: (rows: GridRowModel) => void,
  handleSave: (rowId: string) => void,
  handleCancel: (rowId: string) => void,
  rowModesModel: GridRowModesModel,
  editedRowData: GridRowModel | null,
  handleEditChange: (field: string, value: any) => void,
): GridColumns => [
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: COLUMN_WIDTH.DESCRIPTION_MIN_WIDTH,
    filterOperators: filterStringOps,
    renderCell: (params) => {
      const isEditing = rowModesModel[params.row.id]?.mode === GridRowModes.Edit
      return (
        (isEditing ? (
          <TextField
            fullWidth
            value={editedRowData?.description || ''}
            onChange={(e) => handleEditChange('description', e.target.value)}
            size="small"
            InputProps={{
              style: { fontSize: 'inherit' },
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: 'inherit',
                paddingLeft: '0px',
              },
            }}
          />
        ) : (
          params.value
        )))
    },
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    type: 'dateTime',
    width: COLUMN_WIDTH.EDIT_DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => renderDateTimeCell(params, editedRowData, rowModesModel, handleEditChange, 'startTime'),
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    type: 'dateTime',
    width: COLUMN_WIDTH.EDIT_DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => renderDateTimeCell(params, editedRowData, rowModesModel, handleEditChange, 'endTime'),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: COLUMN_WIDTH.STATUS,
    align: 'center',
    renderCell: (params) => getStatusBadge(params.row.status),
    filterOperators: fliterSingleSelectOps,
    valueOptions: (Object.keys(MAINTENANCE_STATUS) as (keyof typeof MAINTENANCE_STATUS)[]).map((key) => ({
      value: MAINTENANCE_STATUS[key],
      label: MAINTENANCE_STATUS[key],
    })),
  },
  {
    field: 'action',
    headerName: 'Actions',
    headerAlign: 'center',
    width: COLUMN_WIDTH.ACTION,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    renderCell: (params) => {
      const isEditing = rowModesModel[params.row.id]?.mode === GridRowModes.Edit
      return (
        <ActionButtons
          isEditing={isEditing}
          rowId={params.row.id}
          currentRow={params.row}
          handleEdit={handleEdit}
          handleSave={handleSave}
          handleCancel={handleCancel}
          openDeleteModal={openDeleteModal}
        />
      )
    },
  },
]

function ActionButtons({
  isEditing,
  rowId,
  currentRow,
  handleEdit,
  handleSave,
  handleCancel,
  openDeleteModal,
}: {
  isEditing: boolean
  rowId: string
  currentRow: GridRowModel
  handleEdit: (rows: GridRowModel) => void
  handleSave: (id: string) => void
  handleCancel: (id: string) => void
  openDeleteModal: (taskId: number) => void
}) {
  return (
    <>
      {isEditing ? (
        <>
          <IconButton onClick={() => handleSave(rowId)} color="success">
            <CheckCircle />
          </IconButton>
          <IconButton onClick={() => handleCancel(rowId)} color="warning">
            <Cancel />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton onClick={() => handleEdit(currentRow)} color="primary" disabled={[MAINTENANCE_STATUS.COMPLETED, MAINTENANCE_STATUS.DELETED].includes(currentRow.status)}>
            <Edit />
          </IconButton>
          <IconButton color="error" onClick={() => openDeleteModal(Number(rowId))} disabled={!!currentRow.deletedAt}>
            <Delete />
          </IconButton>
          <DetailsButton data={currentRow} />
        </>
      )}
    </>
  )
}

const renderDateTimeCell = (
  params: GridRenderCellParams,
  editedRowData: GridRowModel | null,
  rowModesModel: GridRowModesModel,
  handleEditChange: (field: string, value: any) => void,
  field: 'startTime' | 'endTime',
) => {
  const isEditing = rowModesModel[params.row.id]?.mode === GridRowModes.Edit
  return isEditing ? (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        value={moment(editedRowData?.[field] || null)}
        onChange={(value) => handleEditChange(field, value?.toISOString() || null)}
        slotProps={{
          textField: {
            size: 'small',
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: '0.875rem',
                paddingLeft: '0px',
                paddingRight: '0px',
              },
              '& .MuiInputBase-root': {
                paddingRight: '8px',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  ) : (
    <DateCell date={(new Date(params.value)).toISOString()} />
  )
}

export default MaintenanceGrid
