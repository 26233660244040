import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TextField } from '@mui/material'
import { useUpdateDialog } from '../../../../api/query/dialogs/dialogs'

interface Props {
  description: string
  quickbloxDialogId: string
  open: boolean
  handleClose: (actionTriggered: boolean, newDescription: string) => void
}

function EditDialogDescriptionModal(props: Props) {
  const {
    description, quickbloxDialogId, open, handleClose,
  } = props

  const [newDescription, setNewDescription] = React.useState(' ')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDescription(event.target.value)
  }
  const updateDescription = useUpdateDialog()

  const updateDialogDescription = async () => {
    updateDescription.mutate({ quickbloxDialogId, updateData: { description: newDescription } })
    handleClose(true, newDescription)
  }

  return (
    <Dialog open={open} onClose={() => handleClose(false, '')} fullWidth maxWidth="sm">
      <DialogTitle>
        Update the dialog description.
      </DialogTitle>
      <DialogContent>
        <DialogContentText />
        <TextField
          autoFocus
          margin="dense"
          label="Dialog description"
          fullWidth
          multiline
          rows={4}
          defaultValue={description}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false, '')}>Cancel</Button>
        <Button disabled={newDescription === ' '} onClick={updateDialogDescription} color="info">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialogDescriptionModal
