import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Avatar, Stack } from '@mui/material'
import WidgetTagsAuth, { expectedWidgetUpdateDialogTag } from '../../../common/WidgetTagsAuth'
import { useUpdateDialog } from '../../../../api/query/dialogs/dialogs'

interface Props {
  name: string
  quickbloxDialogId: string
  photo?: string
  open: boolean
  handleClose: (actionTriggered: boolean, newPhoto: string) => void
}

function DeleteDialogPhotoModal(props: Props) {
  const {
    name, quickbloxDialogId, photo, open, handleClose,
  } = props

  const deletePhotoDialog = useUpdateDialog()
  const deletePhoto = async () => {
    deletePhotoDialog.mutate({ quickbloxDialogId, updateData: { photo: null } })
    handleClose(true, '')
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateDialogTag}>
      <Dialog open={open} onClose={() => handleClose(false, '')}>
        <DialogTitle>
          <Stack direction="row">
            <Avatar
              src={photo}
              sx={{ width: 50, height: 50, m: 2 }}
            />
            {`Delete the icon image from  ${name} ?`}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once removed, you can&apos;t undo this operation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deletePhoto} color="error">Delete</Button>
          <Button onClick={() => handleClose(false, '')}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default DeleteDialogPhotoModal
