import { Typography } from '@mui/material'
import {
  DataGrid,
  GridColumnGroupingModel,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { fetchAdmin } from '../../services/fetch'
import { numberFormat } from '../../services/number'
import { initColumns } from '../../constants/kpiConstants'
import { SearchFormDau } from './SearchFormDau'

interface FormDataDau {
  from: moment.Moment | null
  to: moment.Moment | null
  timezone: string
  storeCountryCode: string
}

const initStatus = {
  columns: [] as GridColumns,
  rows: [],
  loading: false,
  columnVisibilityModel: {},
}

const defaultColumn = ['ymd', 'days', 'user_total', 'dau']

const dauColumnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'this_date_user',
    headerName: 'Regular Data Check',
    headerClassName: 'headerBorder',
    headerAlign: 'center',
    children: [
      { field: 'user_total' },
      { field: 'dau' },
    ],
  },
]

function KpiDBPersonalizeDau() {
  const [status, setStatus] = useState(initStatus)

  const updateStatus = (newStatus: any) => {
    setStatus((old) => {
      if (newStatus.columns) {
        return {
          ...old,
          ...newStatus,
        }
      }
      return { ...old, ...newStatus }
    })
  }

  return (
    <DataGrid
      loading={status.loading}
      columns={status.columns}
      rows={status.rows}
      components={{
        Toolbar: SearchFormDau,
      }}
      componentsProps={{
        toolbar: {
          updateStatus,
          initColumns,
        },
      }}
      disableColumnFilter
      disableColumnMenu
      hideFooter
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          overflowWrap: 'break-word',
          wordBreak: 'break-all',
          lineHeight: '1rem',
          whiteSpace: 'normal',
        },
        '.headerBorder': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
          borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
          borderTop: 'solid 2px rgba(0, 0, 0, 1) !important',
          borderBottom: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderLeft': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderRight': {
          borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '& .MuiDataGrid-cell:empty': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader[data-field="dau"], & .MuiDataGrid-columnHeader[data-field="days"]': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '& .MuiDataGrid-columnHeader[data-field="ymd"], & .MuiDataGrid-columnHeader[data-field="days"]': {
          borderTop: 'solid 1px rgba(0, 0, 0, 1) !important',
          marginTop: '-1px',
        },
      }}
      showColumnRightBorder
      showCellRightBorder
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={dauColumnGroupingModel}
    />
  )
}

function Cell(props: { value: number}) {
  const { value } = props
  return (
    <Typography variant="body2">
      {(value || value === 0) ? numberFormat(value) : '0'}
    </Typography>
  )
}

export async function fetchData({
  formData,
  navigate,
}: {
  formData: FormDataDau
  navigate: ReturnType<typeof useNavigate>
}) {
  const columns: GridColumns = []

  if (!formData.from || !formData.to) {
    throw new Error('Invalid date range: "from" and "to" fields must not be null.')
  }

  formData.from.toJSON = () => formData.from!.format('YYYYMMDD')
  formData.to.toJSON = () => formData.to!.format('YYYYMMDD')
  const rows = await fetchAdmin({
    path: 'analytics/kpi/db/dau/count',
    method: 'GET',
    queries: {
      form: JSON.stringify(formData),
    },
  })
    .then((res) => res.json())
    .catch(() => {})
  initColumns.forEach((col) => {
    if (defaultColumn.includes(col.field)) {
      columns.push(initGridColumn(col, formData, navigate))
    }
  })

  return {
    columns,
    rows,
  }
}

export function createLinkDau(value: any, row: any, formData: FormDataDau, navigate: ReturnType<typeof useNavigate>): React.ReactNode {
  const dayUserPage = '/analytics/kpi/db/personalize/dau/users'
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const queryParams = Object.entries(formData)
      .map(([key, data]) => (key === 'from' || key === 'to'
        ? `${encodeURIComponent(key)}=${encodeURIComponent(`${row.id}`)}`
        : `${encodeURIComponent(key)}=${encodeURIComponent(String(data))}`))
      .join('&')
    navigate(`${dayUserPage}?${queryParams}`)
  }

  return (
    <a
      href={dayUserPage}
      onClick={handleClick}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'blue',
      }}
    >
      {value}
    </a>
  )
}

export function initGridColumn(column: GridEnrichedColDef, formData: FormDataDau, navigate: ReturnType<typeof useNavigate>): GridEnrichedColDef {
  const customRenderers: Record<string, (params: any) => React.ReactNode> = {
    dau: ({ value, row }) => createLinkDau(value, row, formData, navigate),
  }
  return {
    ...column,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    cellClassName: 'borderRight',
    renderCell: customRenderers[column.field] || column.renderCell
      || (({ value }) => <Cell value={value} />),
    hideSortIcons: true,
  }
}

export default KpiDBPersonalizeDau
