import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TextField } from '@mui/material'
import { useUpdateDialog } from '../../../../api/query/dialogs/dialogs'

interface Props {
  name: string
  quickbloxDialogId: string
  open: boolean
  handleClose: (actionTriggered: boolean, newName: string) => void
}

function EditDialogNameModal(props: Props) {
  const {
    name, quickbloxDialogId, open, handleClose,
  } = props

  const [newName, setNewName] = React.useState('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value)
  }
  const updateName = useUpdateDialog()

  const updateDialogName = async () => {
    updateName.mutate({ quickbloxDialogId, updateData: { name: newName } })
    handleClose(true, newName)
  }

  return (
    <Dialog open={open} onClose={() => handleClose(false, '')} fullWidth maxWidth="sm">
      <DialogTitle>
        Update the dialog name.
      </DialogTitle>
      <DialogContent>
        <DialogContentText />
        <TextField
          autoFocus
          margin="dense"
          label="Dialog name"
          fullWidth
          multiline
          rows={4}
          defaultValue={name}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false, '')}>Cancel</Button>
        <Button disabled={newName === ''} onClick={updateDialogName} color="info">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialogNameModal
