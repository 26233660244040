import { NavigateBefore } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

function ButtonBack({
  to,
  enableHistoryBack = false,
}: {
  to: string
  enableHistoryBack?: boolean
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const handleBack = () => {
    if (location.key !== 'default' && enableHistoryBack) {
      navigate(-1)
    } else {
      navigate(to)
    }
  }

  return (
    <Button
      startIcon={<NavigateBefore />}
      onClick={() => handleBack()}
    >
      back
    </Button>
  )
}

export default ButtonBack
