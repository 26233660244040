import React, { useEffect } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridColumnGroupingModel,
  GridColumnGroup,
} from '@mui/x-data-grid'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from '@mui/material'

interface CustomToolbarProps {
  initColumns: {
    field: string
    hide?: boolean
    headerName?: string
  }[]
  columnGroupingModel: GridColumnGroupingModel
  updateFormData: (newFormData: { columnVisibility: Record<string, boolean> }) => void
  currentVisibility: Record<string, boolean>
}

function ColumnGroupCheckboxes({
  group,
  level = 0,
  columnVisibility,
  toggleColumnVisibility,
  initColumns,
}: {
  group: GridColumnGroup
  level?: number
  columnVisibility: Record<string, boolean>
  toggleColumnVisibility: (field: string) => void
  initColumns: CustomToolbarProps['initColumns']
}) {
  const indentation = level * 16

  return (
    <Box sx={{ ml: indentation }}>
      <Typography
        variant="subtitle2"
        sx={{
          mt: 1,
          mb: 0.5,
          fontWeight: 'bold',
          color: level === 0 ? 'primary.main' : 'text.primary',
        }}
      >
        {group.headerName || group.groupId}
      </Typography>
      <Box sx={{ ml: 2 }}>
        {group.children?.map((child) => {
          if ('groupId' in child) {
            return (
              <ColumnGroupCheckboxes
                key={child.groupId}
                group={child}
                level={level + 1}
                columnVisibility={columnVisibility}
                toggleColumnVisibility={toggleColumnVisibility}
                initColumns={initColumns}
              />
            )
          } if ('field' in child) {
            const column = initColumns.find((col) => col.field === child.field)
            if (!column) return null

            return (
              <FormControlLabel
                key={child.field}
                control={(
                  <Checkbox
                    checked={columnVisibility[child.field] ?? true}
                    onChange={() => toggleColumnVisibility(child.field)}
                    size="small"
                  />
                )}
                label={column.headerName || column.field}
              />
            )
          }
          return null
        })}
      </Box>
    </Box>
  )
}

function CustomToolbar({
  initColumns,
  columnGroupingModel,
  updateFormData,
  currentVisibility,
}: CustomToolbarProps) {
  const [isPreferencesPanelOpen, setIsPreferencesPanelOpen] = React.useState(false)
  const [columnVisibility, setColumnVisibility] = React.useState<Record<string, boolean>>(
    () => ({ ...currentVisibility }),
  )

  useEffect(() => {
    setColumnVisibility(currentVisibility)
  }, [currentVisibility])

  const handleOpenPreferencesPanel = () => {
    setIsPreferencesPanelOpen(true)
  }

  const handleClosePreferencesPanel = () => {
    setIsPreferencesPanelOpen(false)
    updateFormData({ columnVisibility })
  }

  const toggleColumnVisibility = (field: string) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }))
  }

  const handleClearAll = () => {
    const clearedVisibility: Record<string, boolean> = {}
    initColumns.forEach((column) => {
      if (column.field === 'ymd' || column.field === 'days') {
        clearedVisibility[column.field] = true
      } else {
        clearedVisibility[column.field] = false
      }
    })
    setColumnVisibility(clearedVisibility)
  }

  const handleShowAll = () => {
    const showAllVisibility: Record<string, boolean> = {}
    initColumns.forEach((column) => {
      showAllVisibility[column.field] = true
    })
    setColumnVisibility(showAllVisibility)
  }

  return (
    <>
      <GridToolbarContainer>
        <Button onClick={handleOpenPreferencesPanel} variant="outlined" sx={{ mr: 2 }}>
          Manage Columns
        </Button>
        <GridToolbarExport
          csvOptions={{
            fileName: 'kpi',
          }}
          printOptions={{
            disableToolbarButton: true,
          }}
        />
      </GridToolbarContainer>
      <Dialog
        open={isPreferencesPanelOpen}
        onClose={handleClosePreferencesPanel}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Manage Columns</DialogTitle>
        <DialogContent>
          {columnGroupingModel.map((group) => (
            <ColumnGroupCheckboxes
              key={group.groupId}
              group={group}
              columnVisibility={columnVisibility}
              toggleColumnVisibility={toggleColumnVisibility}
              initColumns={initColumns}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button onClick={handleClearAll} variant="outlined">
                Clear All
              </Button>
              <Button onClick={handleShowAll} variant="outlined">
                Show All
              </Button>
            </Box>
            <Button onClick={handleClosePreferencesPanel} variant="outlined">
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomToolbar
