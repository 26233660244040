import {
  Alert,
  AlertColor,
  Slide,
  SlideProps,
  Snackbar,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

export interface AlertProps {
  severity: AlertColor
  message: string
}

export function useAlert(): [React.ReactElement, (props: AlertProps) => void] {
  const [alertProps, setAlertProps] = useState<AlertProps>()
  const [time, setTime] = useState(0)
  const setAlert = useCallback((props: AlertProps) => {
    setAlertProps(props)
    setTime(Date.now())
  }, [])
  const alert = (
    <CardAlert
      severity={alertProps?.severity}
      message={alertProps?.message}
      time={time}
    />
  )

  return [alert, setAlert]
}

function CardAlert({
  severity,
  message,
  time,
}: {
  severity?: AlertColor
  message?: string
  time: number
}) {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (message) {
      setOpen(true)
    }
  }, [message, time])

  if (!severity || !message) {
    return null
  }

  return (
    <>
      <Alert
        severity={severity}
      >
        {message}
      </Alert>

      <Snackbar
        open={open}
        message={message}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={SlideTransition}
      />
    </>
  )
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />
}
