import { ReactNode } from 'react'
import {
  type MRT_ColumnDef as MrtColumnDef,
} from 'material-react-table'
import BugReportIcon from '@mui/icons-material/BugReport'
import { useNavigate } from 'react-router-dom'
import MrtBaseDataGrid from '../common/MrtBaseDataGrid'
import { DateCell } from '../../app/Timezone'
import CustomDateFilter from '../common/date/CustomDateFilter'
import { useUserKpiTableSearchParams } from '../kpi/hooks/useUserKPITableSearchParam'
import { useGetUsers } from '../../../api/query/users/users'
import CustomStatusFilter from '../common/users/CustomStatusFilter'
import { USERSTATUS } from '../../../constants'

interface UsersData {
  rows: User[];
  totalRows: number;
  phones: string[]
}

type User = {
  quickbloxUserId: string;
  userName: string;
  fullName: string;
  phone: string;
  region: string;
  reporter: string;
  reported: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  userType?: string | null;
  isOfficial?: boolean;
  isStaff?: boolean;
  isAmbassador?: boolean;
  isDebug?: boolean;
}

function UserStatus({ statuses }: { statuses: string[] }) {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      {USERSTATUS
        .filter((status) => statuses.includes(status.value))
        .map((status) => (
          <div key={status.value} style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
            {status.badge && (
              <img
                src={status.badge}
                alt={status.label}
                style={{ width: '20px' }}
              />
            )}
            {status.value === 'isDebug' && (
              <BugReportIcon color="error" />
            )}
          </div>
        ))}
    </div>
  );
}

const getUserStatus = (user: User): ReactNode => {
  const statuses: string[] = [];
  if (user.isOfficial) statuses.push('isOfficial');
  if (user.isStaff) statuses.push('isStaff');
  if (user.isAmbassador) statuses.push('isAmbassador');
  if (user.isDebug) statuses.push('isDebug');

  return <UserStatus statuses={statuses.length ? statuses : ['regular']} />;
}

export function UserDausCard({ user }: { user: UsersData }) {
  const navigate = useNavigate()
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
  } = useUserKpiTableSearchParams()

  queryParams.fixedFilterOp = 'contains'
  queryParams.fixedFilterField = 'phone'
  queryParams.fixedFilterValue = user.phones?.length > 0 ? user.phones.toString() : '-1'
  queryParams.showUserType = '1'

  const { data, refetch } = useGetUsers(queryParams)

  const columns: MrtColumnDef<User>[] = [
    {
      accessorKey: 'userStatus',
      header: 'Status',
      size: 150,
      Cell: ({ row }) => getUserStatus(row.original),
      Filter: () => CustomStatusFilter({
        field: 'userStatus',
        filter: {
          filterOp: '=',
          filterValue: filter.filterValue,
        },
        setFilter,
      }),
      enableSorting: false,
    },
    {
      accessorKey: 'quickbloxUserId',
      header: 'User ID',
      size: (window.innerWidth > 1200 ? 140 : 100),
    },
    {
      accessorKey: 'userName',
      header: 'Username',
      size: 150,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      size: 150,
    },
    {
      accessorKey: 'region',
      header: 'Region',
      size: 120,
    },
    {
      accessorKey: 'reporter',
      header: 'Reporter',
      size: 150,
    },
    {
      accessorKey: 'reported',
      header: 'Reported',
      size: 150,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'updatedAt', filter, setFilter }),
    },
  ]
  const handleRowDoubleClick = (row: User) => {
    const element = document.querySelector('.mainPageTitle')
    if (element) {
      element.textContent = 'User Detail'
    }
    navigate(`/users/${row.quickbloxUserId}`);
  }

  return (
    <div>
      <MrtBaseDataGrid
        columns={columns}
        data={data?.rows || []}
        totalRows={data?.totalRows ?? 0}
        manualFiltering={false}
        manualSorting={false}
        onClearFilters={clearFilters}
        onColumnFiltersChange={setColumnFilters}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        onRefetch={refetch}
        onRowDoubleClick={(row) => handleRowDoubleClick(row.original)}
      />
    </div>
  )
}
