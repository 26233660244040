import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { fetchAdmin } from '../../../services/fetch'
import { QUERY_KEYS } from '../keys'
import { QueryParams } from '../params'

export function useGetCampaigns(params: QueryParams) {
  return useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGNS, params],
    queryFn: async () => {
      const res = await fetchAdmin({
        path: 'campaign',
        method: 'GET',
        queries: { ...params },
      })

      return res.ok
        ? res.json()
        : null
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

export function useGetCampaign(campaignId: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN, campaignId],
    queryFn: async () => {
      const res = await fetchAdmin({
        path: `campaign/${campaignId}`,
        method: 'GET',
      })
      const json = await res.json()

      if (!res.ok) {
        throw json
      }

      return json
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

export function usePostCampaign(options?: UseMutationOptions<Record<string, string>, Record<string, string>, FormData, unknown>) {
  const queryClient = useQueryClient()

  return useMutation(
    async (data) => {
      const res = await fetchAdmin({
        path: 'campaign',
        method: 'POST',
        body: data,
      })
      const json = await res.json()

      if (!res.ok) {
        throw json
      }

      queryClient.invalidateQueries([QUERY_KEYS.CAMPAIGNS])

      return json
    },
    options,
  )
}

export function usePutCampaign(options?: UseMutationOptions<unknown, Record<string, string>, FormData, unknown>) {
  const queryClient = useQueryClient()

  return useMutation(
    async (data) => {
      const id = data.get('id')
      const res = await fetchAdmin({
        path: `campaign/${id}`,
        method: 'PUT',
        body: data,
      })
      const json = await res.json()

      if (!res.ok) {
        throw json
      }

      queryClient.invalidateQueries([QUERY_KEYS.CAMPAIGNS])
      queryClient.invalidateQueries([QUERY_KEYS.CAMPAIGN, id])

      return json
    },
    options,
  )
}
