import React, {
  CSSProperties,
  ReactNode,
  useEffect, useRef, useState,
} from 'react';
import {
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_Row as MrtRow,
} from 'material-react-table';
import Select, { components, GroupBase, MultiValueProps } from 'react-select';
import { Box } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import { DateCell } from '../../app/Timezone';
import { useGetUsers } from '../../../api/query/users/users';
import MrtBaseDataGrid from '../common/MrtBaseDataGrid';
import { styleObj } from '../../../styles/rowStyle';
import CustomDateFilter from '../common/date/CustomDateFilter';
import { useUserTableSearchParams } from './hooks/useUserTableSearchParams';
import { USER_TYPES, USERSTATUS } from '../../../constants';
import CustomStatusFilter from '../common/users/CustomStatusFilter';

interface OptionType {
  value: string;
  label: string;
}

type User = {
  quickbloxUserId: string;
  userName: string;
  fullName: string;
  phone: string;
  region: string;
  reporter: string;
  reported: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  userType?: string | null;
  isOfficial?: boolean;
  isStaff?: boolean;
  isAmbassador?: boolean;
  isDebug?: boolean;
};

function UserStatus({ statuses }: { statuses: string[] }) {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      {USERSTATUS
        .filter((status) => statuses.includes(status.value))
        .map((status) => (
          <div key={status.value} style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
            {status.badge && (
              <img
                src={status.badge}
                alt={status.label}
                style={{ width: '20px' }}
              />
            )}
            {status.value === 'isDebug' && (
              <BugReportIcon color="error" />
            )}
          </div>
        ))}
    </div>
  );
}

const getUserStatus = (user: User): ReactNode => {
  const statuses: string[] = [];
  if (user.isOfficial) statuses.push('isOfficial');
  if (user.isStaff) statuses.push('isStaff');
  if (user.isAmbassador) statuses.push('isAmbassador');
  if (user.isDebug) statuses.push('isDebug');

  return <UserStatus statuses={statuses.length ? statuses : ['regular']} />;
};

function Users() {
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
    showUserType,
    setOnUserTypeChange,
  } = useUserTableSearchParams();

  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(USER_TYPES);
  const selectRef = useRef<HTMLDivElement | null>(null)
  const width = Math.max(100, selectedOptions.length * 100)

  useEffect(() => {
    if (showUserType) {
      setSelectedOptions(showUserType);
    }
  }, [showUserType]);

  useEffect(() => {
    if (setOnUserTypeChange) {
      setOnUserTypeChange(selectedOptions);
    }
  }, [selectedOptions, setOnUserTypeChange]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
    })

    const currentRef = selectRef.current

    if (currentRef) {
      resizeObserver.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef)
      }
    };
  }, [])

  const columns: MrtColumnDef<User>[] = [
    {
      accessorKey: 'userStatus',
      header: 'Status',
      size: 90,
      Cell: ({ row }) => getUserStatus(row.original),
      Filter: () => CustomStatusFilter({
        field: 'userStatus',
        filter: {
          filterOp: '=',
          filterValue: filter.filterValue,
        },
        setFilter,
      }),
      enableSorting: false,
    },
    {
      accessorKey: 'quickbloxUserId',
      header: 'User ID',
      size: 120,
    },
    {
      accessorKey: 'userName',
      header: 'Username',
      size: 150,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      size: 130,
    },
    {
      accessorKey: 'region',
      header: 'Region',
      size: 120,
    },
    {
      accessorKey: 'reporter',
      header: 'Reports Made',
      size: 160,
    },
    {
      accessorKey: 'reported',
      header: 'Reported By Others',
      size: 200,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
    },
  ]

  const {
    data,
    isError,
    isLoading,
    refetch,
  } = useGetUsers(queryParams);

  const customStyle = (row: MrtRow<User>) => ({
    ...(row.original.userType === '1' ? {} : {}),
    ...(row.original.userType === '2' ? styleObj.error : {}),
    ...(row.original.userType === '3' ? styleObj.disabled : {}),
  });

  const handleUserTypeChange = (newSelectedOptions: OptionType[]) => {
    if (setOnUserTypeChange) {
      setOnUserTypeChange([...newSelectedOptions]);
    }
  };

  const renderUserControls = () => (
    <Box
      sx={{
        display: 'flex', alignItems: 'center', gap: 2, minWidth: `${width}`,
      }}
      ref={selectRef}
    >
      <Select
        isMulti
        closeMenuOnSelect={false}
        value={showUserType}
        onChange={handleUserTypeChange}
        options={USER_TYPES}
        placeholder="Select user types..."
        menuPortalTarget={document.body}
        components={{ MultiValue }}
        styles={{
          menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 9999 }),
          control: (base: CSSProperties) => ({
            ...base,
            minWidth: '100%',
          }),
        }}
        classNamePrefix="select"
      />
    </Box>
  );

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows ?? 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      customRowStyle={customStyle}
      defaultPath="/users"
      renderTopToolbarCustomContent={renderUserControls}
    />
  );
}

function MultiValue(props: MultiValueProps<OptionType, true, GroupBase<OptionType>>) {
  const classNameMap: { [key: string]: string } = {
    Normal: 'multi-value-normal',
    Deleted: 'multi-value-deleted',
    Suspended: 'multi-value-suspended',
  }
  const className = classNameMap[props?.data.label] || ''
  return (
    <div style={{ marginRight: '3px' }}>
      <components.MultiValue {...props} className={className}>
        <span style={{ paddingLeft: '2px', paddingRight: '2px' }}>{props?.data.label}</span>
      </components.MultiValue>
    </div>
  );
}

export default Users;
