import {
  green, lightBlue, red, yellow,
} from '@mui/material/colors';

export const styleObj = {
  error: {
    bgcolor: red[50],
    '&:hover, &.Mui-hovered': {
      bgcolor: red[100],
    },
  },
  info: {
    bgcolor: lightBlue[50],
    '&:hover, &.Mui-hovered': {
      bgcolor: lightBlue[100],
    },
  },
  disabled: {
    bgcolor: yellow[50],
    '&:hover, &.Mui-hovered': {
      bgcolor: yellow[100],
    },
  },
  completed: {
    bgcolor: green[50],
    '&:hover, &.Mui-hovered': {
      bgcolor: green[100],
    },
  },
}
