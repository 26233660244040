import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useGetCampaigns } from '../../api/query/campaign/campaigns'
import { FilterParams } from '../../api/query/params'
import { DateCell } from '../app/Timezone'
import CustomDateFilter from '../v2/common/date/CustomDateFilter'
import MrtBaseDataGrid from '../v2/common/MrtBaseDataGrid'
import { useBaseTableSearchParams } from '../v2/hooks/useBaseTableSearchParams'

function Campaigns() {
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
  } = useBaseTableSearchParams({
    defaultInit: {
      sortField: 'id',
      sortDir: 'desc',
    },
  })
  const columns = getColumns({ filter, setFilter })

  const {
    data, isError, isLoading, refetch,
  } = useGetCampaigns(queryParams)

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows || 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      renderTopToolbarCustomContent={() => <ButtonCreate />}
      defaultPath="/campaign"
    />
  )
}

function ButtonCreate() {
  const navigate = useNavigate()

  return (
    <Button
      variant="contained"
      color="info"
      size="large"
      onClick={() => navigate('/campaign/create')}
    >
      create
    </Button>
  )
}

function getColumns({
  filter,
  setFilter,
}: {
  filter: FilterParams,
  setFilter: (newFilter: FilterParams) => void
}) {
  return [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 100,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      size: 300,
    },
    {
      accessorKey: 'startAt',
      header: 'StartAt',
      size: 200,
      Cell: DateCell,
    },
    {
      accessorKey: 'endAt',
      header: 'EndAt',
      size: 200,
      Cell: DateCell,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 200,
      Cell: DateCell,
      Filter: () => CustomDateFilter({
        field: 'createdAt',
        filter,
        setFilter,
      }),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 200,
      Cell: DateCell,
      Filter: () => CustomDateFilter({
        field: 'updatedAt',
        filter,
        setFilter,
      }),
    },
  ]
}

export default Campaigns
