import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Button,
  Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchAdmin } from '../../services/fetch'
import { Tab, TabPanel, Tabs } from '../common/Tabs'
import MomentDetails from '../moment/MomentDetails'
import UserDetails from '../user/UserDetails'
import WeeklyLikeMomentLikesGrid from './WeeklyLikeMomentLikesGrid'

interface Props {
  weeklyLikeMomentId: string | number,
  exitDetails?: () => void
}

interface DetailsProps {
  likeMoment?: {
    id: number,
    quickbloxUserId: number,
    momentIdentifier: string,
    likeNum: number,
    week: number,
  },
  rewardRequested: boolean,
  totalRequested: number
}

function WeeklyLikeMomentDetails(props: Props) {
  const {
    weeklyLikeMomentId, exitDetails,
  } = props
  const navigate = useNavigate()
  const handleExit = useCallback(() => (exitDetails ? exitDetails() : navigate(-1)), [exitDetails, navigate])
  const [detail, setDetail] = useState<DetailsProps>()
  const fetchLikeMoment = useCallback(() => (
    fetchAdmin({
      path: `campaign/weekly-like-moments/${weeklyLikeMomentId}`,
      method: 'GET',
    })
      .then((res) => res.json())
      .then((json) => setDetail(json))
      .catch((error) => console.error(error)) // eslint-disable-line no-console
  ), [weeklyLikeMomentId])

  useEffect(() => {
    fetchLikeMoment()
  }, [fetchLikeMoment])

  return (
    <>
      <Button
        startIcon={<NavigateBeforeIcon />}
        onClick={handleExit}
      >
        back
      </Button>

      {
        !!detail && !!detail.likeMoment && (
          <>
            <Tabs>
              <Tab label="detail" />
              <Tab label="moment" />
              <Tab label="like" />
              <Tab label="user" />
            </Tabs>

            <TabPanel>
              <TableDetail
                likeMoment={detail.likeMoment}
                rewardRequested={detail.rewardRequested}
                totalRequested={detail.totalRequested}
              />
            </TabPanel>

            <TabPanel>
              <MomentDetails
                identifier={detail.likeMoment.momentIdentifier}
                backText=""
                exitDetails={() => {}}
              />
            </TabPanel>

            <TabPanel>
              <WeeklyLikeMomentLikesGrid
                weeklyLikeMomentId={weeklyLikeMomentId}
              />
            </TabPanel>

            <TabPanel>
              <UserDetails
                quickbloxUserId={detail.likeMoment.quickbloxUserId}
                backText=""
                exitUserDetails={() => {}}
              />
            </TabPanel>
          </>
        )
      }
    </>
  )
}

function TableDetail(props: Required<DetailsProps>) {
  const { likeMoment, rewardRequested, totalRequested } = props
  const week = likeMoment.week.toString()
  const term = DateTime.fromFormat(week.slice(0, 4), 'yyyy').set({ weekNumber: Number.parseInt(week.slice(4, 6)) })

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>term</TableCell>
            <TableCell>
              {term.startOf('week').toFormat('yyyy/MM/dd')}
              &nbsp;-&nbsp;
              {term.endOf('week').toFormat('yyyy/MM/dd')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>quickbloxUserId</TableCell>
            <TableCell>{likeMoment.quickbloxUserId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>momentIdentifier</TableCell>
            <TableCell>{likeMoment.momentIdentifier}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>like</TableCell>
            <TableCell>{likeMoment.likeNum}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GiftCode Requested</TableCell>
            <TableCell>{rewardRequested ? 'yes' : 'no'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Requested</TableCell>
            <TableCell>{totalRequested}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WeeklyLikeMomentDetails
