import { useEffect, useState } from 'react'
import {
  CircularProgress, Typography,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { fetchAdmin } from '../../services/fetch'
import { UserDausCard } from '../v2/kpi/UserDauCard'

function DauDetails() {
  const location = useLocation()
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true)
  const queryString = window.location.search
  const targetPath = location.pathname

  useEffect(() => {
    async function fetchData() {
      try {
        const queryParams = getQueryParams(queryString)
        const element = document.querySelector('.mainPageTitle')
        if (element) {
          element.textContent = `Daily Active User (DAU): ${queryParams.from}`
        }
        const rows = await fetchAdmin({
          path: 'analytics/kpi/db/dau/users',
          method: 'GET',
          queries: {
            form: JSON.stringify(queryParams),
          },
        })
          .then((res) => res.json())
          .catch(() => {})
        setData(rows)
      } catch (error) {
        console.error('Error fetching data:', error) // eslint-disable-line no-console
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [queryString, targetPath])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div>
      {data ? (
        <pre>
          <UserDausCard user={data} />
        </pre>
      ) : (
        <Typography variant="body1">No data found.</Typography>
      )}
    </div>
  )
}

function getQueryParams(queryString: string): Record<string, string> {
  const params: Record<string, string> = {}
  const query = queryString.startsWith('?') ? queryString.slice(1) : queryString
  const pairs = query.split('&')
  for (const pair of pairs) {
    const [key, value] = pair.split('=')
    if (key) {
      params[decodeURIComponent(key)] = decodeURIComponent(value || '')
    }
  }

  return params
}

export default DauDetails
