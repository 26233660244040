import { useState, useEffect, useCallback } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import ArrowLeft from '@mui/icons-material/NavigateBefore'
import Delete from '@mui/icons-material/Delete'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import EditIcon from '@mui/icons-material/Edit'
import { Badge, colors } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { checkIs1on1Chat } from '../../../../constants'
import DeleteDialogPhotoModal from '../modals/DeleteDialogPhotoModal'
import EditDialogNameModal from '../modals/EditDialogNameModal'
import EditDialogDescriptionModal from '../modals/EditDialogDescriptionModal'
import WidgetTagsAuth, { expectedWidgetUpdateDialogTag, expectedWidgetDeleteDialogTag } from '../../../common/WidgetTagsAuth'
import UserProfileLink from '../../../user/UserProfileLink'
import {
  useBanDialog, useDeleteDialog, useDeleteParticipants, useGetDialogDetails, useGetMemberDialogs,
  useSwitchFeatureDialog,
} from '../../../../api/query/dialogs/dialogs'
import { useConfirmationModal } from '../../hooks/useConfirmationModal'
import ConfirmationModal from '../../common/modals/ConfirmationModal'
import { DialogDetailsType } from '../types'

function DialogDetails() {
  const { quickbloxDialogId } = useParams()
  const ONE_ON_ONE_CHAT = '1 on 1 Chat'
  const [details, setDetails] = useState<DialogDetailsType>({
    name: '',
    quickbloxDialogId,
    photo: undefined as undefined | string,
    description: '',
    type: '',
    categoryEmoji: '',
    categoryName: '',
    creatorUserId: 0,
    allowSearch: false,
    visible: true,
    isFeatured: false,
    // totalGuests: 0 // TODO: decide if we want to show this
    // totalHosts: 0
    // totalMembers: 0
    // totalPeekers: 0
  })

  const [memberDetails, setMemberDetails] = useState({
    participants: [] as Array<{
      quickbloxUserId: number
      userName: string
      fullName: string
      role: string
      isOfficial: boolean
      isStaff: boolean
      isAmbassador: boolean
      customData: {
        avatarUrl: string
      }
    }>,
  })

  const {
    modalState,
    handleOpen,
    handleClose,
    handleConfirm,
  } = useConfirmationModal()

  const deleteParticipant = useDeleteParticipants()

  const [is1on1Chat, setIs1on1Chat] = useState(false)

  const [showDeleteDialogPhoto, setShowDeleteDialogPhoto] = useState({ open: false })
  const [showEditDialogName, setShowEditDialogName] = useState({ open: false })
  const [showEditDialogDescription, setShowEditDialogDescription] = useState({ open: false })

  const {
    data: dialogDetailsData, isLoading: isDialogDetailsLoading, isError: dialogDetailsError,
  } = useGetDialogDetails(quickbloxDialogId)
  const {
    data: membersData, isLoading: isMembersLoading, isError: membersError,
  } = useGetMemberDialogs(quickbloxDialogId)

  const navigate = useNavigate()

  const fetchData = useCallback(async () => {
    if (dialogDetailsData && membersData) {
      setIs1on1Chat(checkIs1on1Chat(dialogDetailsData.data.type))
      setDetails({
        name: dialogDetailsData.data.name,
        quickbloxDialogId,
        photo: dialogDetailsData.data.photo,
        description: dialogDetailsData.data.description,
        type: dialogDetailsData.data.type,
        categoryEmoji: dialogDetailsData.data.categoryEmoji,
        categoryName: dialogDetailsData.data.categoryName,
        creatorUserId: dialogDetailsData.data.creatorUserId,
        allowSearch: dialogDetailsData.data.allowSearch,
        visible: dialogDetailsData.data.visible,
        isFeatured: dialogDetailsData.data.featured,
      })

      setMemberDetails({
        participants: membersData.data,
      })
    }
  }, [dialogDetailsData, membersData, quickbloxDialogId])

  useEffect(() => {
    fetchData()
  }, [dialogDetailsData, fetchData, membersData, quickbloxDialogId])

  const goBack = () => {
    navigate(-1)
  }

  const handleModalClose = (type: string, actionTriggered: boolean, newData: string) => {
    if (type === 'deleteDialogPhoto') {
      setShowDeleteDialogPhoto({ open: false })
      if (actionTriggered) {
        setDetails((prev) => ({
          ...prev,
          photo: newData,
        }))
      }
    }
    if (type === 'editDialogName') {
      setShowEditDialogName({ open: false })
      if (actionTriggered) {
        setDetails((prev) => ({
          ...prev,
          name: newData,
        }))
      }
    }
    if (type === 'editDialogDescription') {
      setShowEditDialogDescription({ open: false })
      if (actionTriggered) {
        setDetails((prev) => ({
          ...prev,
          description: newData,
        }))
      }
    }
  }

  const SmallIconButton = styled(IconButton)(({ theme }) => ({
    width: 40,
    height: 40,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: colors.grey[200],
    },
    border: `2px solid ${theme.palette.divider}`,
  }))

  return (
    <>
      <ResponsiveButton
        startIcon={<ArrowLeft />}
        onClick={goBack}
      >
        Dialogs
      </ResponsiveButton>
      <Stack direction="row" mx={{ m: 2 }}>
        {is1on1Chat || !details.photo
          ? (
            <Avatar
              src={details.photo}
              sx={{
                width: { xs: 60, sm: 80, md: 100 },
                height: { xs: 60, sm: 80, md: 100 },
                m: { xs: 1, sm: 2 },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                }}
              >
                {details.name ? details.name.charAt(0) : '?'}
              </Typography>
            </Avatar>
          )
          : (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={(
                <SmallIconButton
                  onClick={() => setShowDeleteDialogPhoto({ open: true })}
                  sx={{ color: 'text.secondary' }}
                >
                  <Delete />
                </SmallIconButton>
              )}
              sx={{ m: 2 }}
            >
              <Avatar
                src={details.photo}
                sx={{
                  width: { xs: 60, sm: 80, md: 100 },
                  height: { xs: 60, sm: 80, md: 100 },
                  m: { xs: 1, sm: 2 },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                  }}
                >
                  {details.name ? details.name.charAt(0) : '?'}
                </Typography>
              </Avatar>
            </Badge>
          )}
        <Stack direction="column" justifyContent="center">
          {is1on1Chat
            ? (
              <Typography variant="h4" m={0} sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                {ONE_ON_ONE_CHAT}
              </Typography>
            )
            : (
              <Stack direction="row">
                <Typography variant="h4" m={0}>
                  {details.name}
                </Typography>
                <IconButton
                  onClick={() => setShowEditDialogName({ open: true })}
                  sx={{ color: 'text.secondary' }}
                >
                  <EditIcon />
                </IconButton>
              </Stack>
            )}
          <Typography variant="h5" sx={{ color: 'text.secondary', fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' } }}>
            {details.quickbloxDialogId}
          </Typography>
        </Stack>
      </Stack>
      {is1on1Chat
        ? null // TODO: if you want to delete 1 on 1 chat, you need to make new api call, like helium api, 'DELTE friends/remove'
        : (
          <DialogDetailButton details={details} />
        )}
      <Box sx={{ flexGrow: 1, paddingTop: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card sx={{ p: 1 }}>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Details
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Type
                        </TableCell>
                        <TableCell>
                          {details.type}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Category
                        </TableCell>
                        <TableCell>
                          {details.categoryName}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Creator User ID
                        </TableCell>
                        <TableCell>
                          <UserProfileLink userId={details.creatorUserId} />
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Allow Search
                        </TableCell>
                        <TableCell>
                          {details.allowSearch ? 'true' : 'false'}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {is1on1Chat
                        ? null
                        : (
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                              Description
                            </TableCell>
                            <TableCell>
                              {details.description}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => setShowEditDialogDescription({ open: true })}
                                sx={{ color: 'text.secondary' }}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Participants
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>FullName</TableCell>
                        <TableCell>UserName</TableCell>
                        <TableCell>QuickbloxUserId</TableCell>
                        {is1on1Chat
                          ? null
                          : (
                            <>
                              <TableCell>Role</TableCell>
                              <TableCell />
                            </>
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {memberDetails.participants.map((participant) => (
                        <TableRow
                          key={participant.quickbloxUserId}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {participant.fullName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`@${participant.userName}`}
                          </TableCell>
                          <TableCell>
                            <UserProfileLink userId={participant.quickbloxUserId} />
                          </TableCell>
                          {is1on1Chat
                            ? null
                            : (
                              <>
                                <TableCell>{participant.role}</TableCell>
                                <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateDialogTag || expectedWidgetDeleteDialogTag}>
                                  <TableCell>
                                    {details.creatorUserId !== participant.quickbloxUserId && !is1on1Chat
                                      ? (
                                        <IconButton
                                          color="error"
                                          onClick={() => handleOpen({
                                            title: `Remove ${participant.userName} as participant? Once removed, you can't undo this operation.`,
                                            confirmLabel: 'Delete',
                                            onConfirm: () => {
                                              deleteParticipant.mutate({
                                                quickbloxDialogId: details.quickbloxDialogId ?? '', qbUserId: participant.quickbloxUserId,
                                              })
                                              setMemberDetails((prevDetails) => ({
                                                ...prevDetails,
                                                participants: prevDetails.participants.filter((p) => p.quickbloxUserId !== participant.quickbloxUserId),
                                              }))
                                            },
                                          })}
                                        >
                                          <PersonRemoveIcon />
                                        </IconButton>
                                      )
                                      : null}
                                  </TableCell>
                                </WidgetTagsAuth>
                              </>
                            )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <DeleteDialogPhotoModal name={details.name} quickbloxDialogId={details.quickbloxDialogId || ''} photo={details.photo} open={showDeleteDialogPhoto.open} handleClose={(actionTriggered, newPhoto) => handleModalClose('deleteDialogPhoto', actionTriggered, newPhoto)} />
        <EditDialogNameModal name={details.name} quickbloxDialogId={details.quickbloxDialogId || ''} open={showEditDialogName.open} handleClose={(actionTriggered, newName) => handleModalClose('editDialogName', actionTriggered, newName)} />
        <EditDialogDescriptionModal description={details.description} quickbloxDialogId={details.quickbloxDialogId || ''} open={showEditDialogDescription.open} handleClose={(actionTriggered, newDescription) => handleModalClose('editDialogDescription', actionTriggered, newDescription)} />
        <ConfirmationModal {...modalState} onConfirm={handleConfirm} onCancel={handleClose} />
      </Box>
    </>
  )
}

export default DialogDetails

const ResponsiveButton = styled(Button)(({ theme }) => ({
  height: '56px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    padding: '4px 8px',
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '& img': {
      height: 50,
    },
  },
}))

function DialogDetailButton({ details }: { details: DialogDetailsType }) {
  const {
    modalState,
    handleOpen,
    handleClose,
    handleConfirm,
  } = useConfirmationModal()

  const deleteDialog = useDeleteDialog()
  const banUser = useBanDialog()
  const switchFeatured = useSwitchFeatureDialog()

  const [loading, setLoading] = useState(false)

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
      >
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteDialogTag}>
          <ResponsiveButton
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            sx={{
              '&.Mui-disabled': { backgroundColor: 'rgba(255, 134, 120, 0.8)' },
            }}
            onClick={() => handleOpen({
              title: `Delete the dialog "${details.name}"? Once removed, you can't undo this operation.`,
              confirmLabel: 'Delete',
              onConfirm: () => deleteDialog.mutate(details.quickbloxDialogId),
            })}
          >
            Delete
          </ResponsiveButton>
        </WidgetTagsAuth>
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateDialogTag}>
          {(details.type !== 'friend')
            && (
              <ResponsiveButton
                variant={details.visible ? 'outlined' : 'contained'}
                color="error"
                startIcon={<Delete />}
                onClick={() => handleOpen({
                  title: `Are you sure you want to ${details.visible ? 'ban' : 'unban'} this dialog?`,
                  confirmLabel: details.visible ? 'Ban' : 'Unban',
                  onConfirm: () => banUser.mutate(details.quickbloxDialogId),
                })}
              >
                Shadow BAN
              </ResponsiveButton>
            )}
        </WidgetTagsAuth>
        <ResponsiveButton
          variant={details.isFeatured ? 'contained' : 'outlined'}
          color="info"
          onClick={() => handleOpen({
            title: details.isFeatured ? 'Remove the dialog from featured list' : 'Add the dialog to featured list',
            confirmLabel: details.isFeatured ? 'Remove' : 'Add',
            onConfirm: async () => {
              setLoading(true)
              try {
                switchFeatured.mutate({ quickbloxDialogId: details.quickbloxDialogId ?? '', featured: !details.isFeatured })
                handleClose()
                details.isFeatured = !details.isFeatured
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error add or remove featured list dialog:', error)
              } finally {
                setLoading(false)
              }
            },
          })}
        >
          {(loading && 'Processing...') || 'Featured'}
        </ResponsiveButton>
      </Stack>
      <ConfirmationModal
        {...modalState}
        onConfirm={handleConfirm}
        onCancel={handleClose}
      />
    </>
  )
}
