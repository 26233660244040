import { Box, Stack } from '@mui/material'
import { GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { COLUMN_WIDTH, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, { buildDetailsButton, DateCell } from '../common/DataGrid'

const initDateWeek = DateTime.now().minus({ week: 1 }).endOf('week')

function WeeklyLikeMomentsGrid({
  show,
  refresh,
}: {
  show: boolean,
  refresh: boolean,
}) {
  const onRowDoubleClick = (params: GridRowParams | GridRenderCellParams) => {
    eventBus.dispatch(EVENT.SHOW_DETAILS, params.row)
  }

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="campaign/weekly-like-moments"
      columns={columns}
      toolbarComponent={SearchToolbar}
      initToolbarQueries={initToolbarQueries}
      onRowDoubleClick={onRowDoubleClick}
    />
  )
}

const DetailsButton = buildDetailsButton({ event: EVENT.SHOW_DETAILS })

const initToolbarQueries = {
  week: initDateWeek.toFormat('kkkkWW'),
}

const columns: GridColumns = [
  {
    field: 'likeNum',
    headerName: 'Like',
    width: 75,
    sortable: false,
    filterable: false,
  },
  {
    field: 'quickbloxUserId',
    headerName: 'User ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    sortable: false,
    filterable: false,
  },
  {
    field: 'momentIdentifier',
    headerName: 'Identifier',
    width: 350,
    sortable: false,
    filterable: false,
  },
  {
    field: 'rewardRequested',
    headerName: 'GiftCode Requested',
    width: 150,
    sortable: false,
    filterable: false,
    renderCell: (row) => <>{row.row.rewardRequested ? 'yes' : 'no'}</>,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
    sortable: false,
    filterable: false,
  },
  {
    field: 'detailsButton',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

function SearchToolbar(props: any) {
  const { setToolbarQueries } = props
  const [dateWeek, setDateWeek] = useState(initDateWeek)

  const updateDateWeek = (date: DateTime | null) => {
    if (date) {
      setDateWeek(date)
      setToolbarQueries((old: object) => ({
        ...old,
        week: date.endOf('week').toFormat('kkkkWW'),
      }))
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box padding={1}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="start"
            value={dateWeek.startOf('week')}
            onChange={(value) => { updateDateWeek(value) }}
            format="yyyy/MM/dd"
            slotProps={{
              textField: {
                sx: { maxWidth: 170 },
              },
            }}
          />

          <DatePicker
            label="end"
            value={dateWeek.endOf('week')}
            onChange={(value) => { updateDateWeek(value) }}
            format="yyyy/MM/dd"
            slotProps={{
              textField: {
                sx: { maxWidth: 170 },
              },
            }}
          />
        </Stack>
      </Box>
    </LocalizationProvider>
  )
}

export default WeeklyLikeMomentsGrid
