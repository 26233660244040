import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import { USERSTATUS } from '../../../../constants';
import { FilterProps } from '../types';

function CustomStatusFilter({ field, filter, setFilter }: FilterProps) {
  const handleSelectChange = (event: SelectChangeEvent) => {
    setFilter({
      ...filter,
      filterField: field,
      filterValue: event.target.value,
    });
  };

  return (
    <Select
      value={filter.filterValue || ''}
      onChange={handleSelectChange}
      variant="standard"
      displayEmpty
      fullWidth
      renderValue={(selected) => (selected ? USERSTATUS.find((status) => status.value === selected)?.label : <span style={{ color: '#9e9e9e' }}>Filter by Status</span>)}
    >
      {USERSTATUS.map((status) => (
        <MenuItem
          key={status.value}
          value={status.value}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {status.label}
          {status.badge && (
            <img
              src={status.badge}
              alt={status.label}
              style={{ width: '20px', marginLeft: '5px' }}
            />
          )}
          {status.value === 'isDebug' && (
            <BugReportIcon color="error" style={{ marginLeft: '5px' }} />
          )}
        </MenuItem>
      ))}
    </Select>
  );
}

export default CustomStatusFilter;
