import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Theme, styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { adminTheme } from '../../config'
import AccountSettingButton from '../common/AccountSetting'
import SideMenu, { getPageTitle } from './SideMenu'
import { SelectTimeZone } from './Timezone'

const drawerWidth = 225

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(0)} + 1px)`, // width: `calc(${theme.spacing(7)} + 1px)`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarCustomProps {
  open: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarCustomProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => getOptions(theme, open) as any)

function getOptions(theme: Theme, open: boolean | undefined) {
  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }
}

function Layout(props: { userName: string, userRole: string }) {
  const { userName, userRole } = props
  const theme = adminTheme
  const [open, setOpen] = useState(false)
  const [isManual, setIsManual] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
    setIsManual(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setIsManual(false)
  }

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      // If mouse is near the left edge of the screen (within 20px)
      if (e.clientX <= 25) {
        setOpen(true) // Show the sidebar
      } else if (e.clientX > 220 && !isManual) {
        setOpen(false) // Hide the sidebar when mouse moves away
      }
    };

    // Attach the mousemove event listener
    window.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [open, isManual])

  useEffect(() => {
    if (window.innerWidth > 600) {
      setOpen(true)
      setIsManual(true)
    }
  }, [])

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: '-15px',
              marginRight: '20px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <PageTitle />

          <SelectTimeZone />

          <AccountSettingButton
            userName={userName}
            userRole={userRole}
          />
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box
            component="img"
            sx={{ height: 25 }}
            src={`${process.env.PUBLIC_URL}/icon.png`}
          />
          <Typography sx={{ mx: 1 }} variant="h6" noWrap component="div">
            Helium Admin
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />

        <SideMenu />
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '90vh' }}>
        <DrawerHeader />

        <Outlet />
      </Box>
    </>
  )
}

function PageTitle() {
  const location = useLocation()

  return (
    <Typography variant="h6" noWrap component="div" className="mainPageTitle" sx={{ flexGrow: 1 }}>
      {getPageTitle(location.pathname)}
    </Typography>
  )
}

export default Layout
