import { NavigateBefore } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimeField } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetCampaign, usePostCampaign, usePutCampaign } from '../../api/query/campaign/campaigns'
import { AlertProps, useAlert } from '../../hooks/Alert'
import { TimezoneContext } from '../app/Timezone'
import ConfirmModal from '../common/ConfirmModal'

type FormProps = Record<string, string | boolean | Set<any> | File | DateTime | null>
type FnSetForm = (form: FormProps) => void
interface FormColumn {
  key: string
  type?: string
  values?: string[]
  toJson?: boolean
  readOnly?: boolean
  description?: string
}

const FORM_COLUMNS: FormColumn[] = [
  {
    key: 'id',
    description: 'Campaign ID, if not entered, will be automatically numbered',
  },
  {
    key: 'name',
    description: 'Campaign name, displayed only on the administration screen',
  },
  {
    key: 'startAt',
    type: 'date',
    description: 'Campaign display start date and time',
  },
  {
    key: 'endAt',
    type: 'date',
    description: 'Campaign display end date and time',
  },
  {
    key: 'entryRequired',
    type: 'bool',
    toJson: true,
    description: 'If enabled, the campaign will be displayed only to users who have entered',
  },
  {
    key: 'region',
    type: 'array',
    values: ['US', 'JP'],
    toJson: true,
    description: 'Regions for campaign display, ignored if entry is required',
  },
  {
    key: 'backgroundColor',
    toJson: true,
    description: 'Background color of campaign page, color code (e.g. #ffffff) or name (e.g. black)',
  },
  {
    key: 'imageBanner',
    type: 'image',
    toJson: true,
    description: 'Banner image displayed in the application',
  },
  {
    key: 'imageTop',
    type: 'image',
    toJson: true,
    description: 'Top image of the campaign page',
  },
  {
    key: 'imageBottom',
    type: 'image',
    toJson: true,
    description: 'Bottom image of campaign page',
  },
  {
    key: 'createdAt',
    type: 'date',
    readOnly: true,
  },
  {
    key: 'updatedAt',
    type: 'date',
    readOnly: true,
  },
  {
    key: 'deletedAt',
    type: 'date',
    readOnly: true,
  },
]

function CampaignDetails() {
  const { id } = useParams()
  const [campaignId, setCampaignId] = useState(id)
  const [alert, setAlert] = useAlert()

  return (
    <>
      {alert}

      <ButtonBack />

      <Typography variant="h3">
        {
          campaignId
            ? `Campaign #${campaignId}`
            : 'Campaign'
        }
      </Typography>

      {
        campaignId
          ? (
            <CardUpdate
              campaignId={campaignId}
              setAlert={setAlert}
            />
          )
          : (
            <CardCreate
              setCampaignId={setCampaignId}
              setAlert={setAlert}
            />
          )
      }
    </>
  )
}

function CardCreate({
  setCampaignId,
  setAlert,
}: {
  setCampaignId: (id: string) => void
  setAlert: (props: AlertProps) => void
}) {
  const [modalOpenTrigger, setModalOpenTrigger] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [form, setForm] = useForm()
  const mutation = usePostCampaign({
    onSuccess: (data) => {
      setCampaignId(data.id)
      setAlert({
        severity: 'success',
        message: 'Successfully created campaign',
      })
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: err?.message
          ? err.message
          : 'Failed create campaign',
      })
    },
    onSettled: () => {
      setDisabledSubmit(false)
    },
  })
  const handleSubmit = useCallback(() => {
    setModalOpenTrigger((old) => !old)
  }, [setModalOpenTrigger])
  const handleAccept = useCallback(() => {
    setDisabledSubmit(true)

    mutation.mutate(fromFormProps(form))
  }, [form, mutation, setDisabledSubmit])

  return (
    <>
      <CardForm
        form={form}
        setForm={setForm}
        labelSubmit="create"
        disabledSubmit={disabledSubmit}
        handleSubmit={handleSubmit}
      />

      <ConfirmModal
        openTrigger={modalOpenTrigger}
        title="Create Campaign"
        message="Do you want to create a campaign?"
        onAccept={handleAccept}
      />
    </>
  )
}

function CardUpdate({
  campaignId,
  setAlert,
}: {
  campaignId: string
  setAlert: (props: AlertProps) => void
}) {
  const [modalOpenTrigger, setModalOpenTrigger] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const { isSuccess, data } = useGetCampaign(campaignId)
  const mutation = usePutCampaign({
    onSuccess: () => {
      setAlert({
        severity: 'success',
        message: 'Successfully updated campaign',
      })
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: err?.message
          ? err.message
          : 'Failed update campaign',
      })
    },
    onSettled: () => {
      setDisabledSubmit(false)
    },
  })
  const [form, setForm] = useForm()
  const handleSubmit = useCallback(() => {
    setModalOpenTrigger((old) => !old)
  }, [setModalOpenTrigger])
  const handleAccept = () => {
    setDisabledSubmit(true)

    mutation.mutate(fromFormProps(form))
  }

  useEffect(() => {
    if (isSuccess && data) {
      setForm(toFormProps(data))
    }
  }, [isSuccess, data, setForm])

  if (!isSuccess || !data) {
    return <LinearProgress />
  }

  return (
    <>
      <CardForm
        form={form}
        setForm={setForm}
        labelSubmit="update"
        disabledSubmit={disabledSubmit}
        handleSubmit={handleSubmit}
        readOnlyColumns={['id']}
      />

      <ConfirmModal
        openTrigger={modalOpenTrigger}
        title="Update Campaign"
        message="Do you want to update a campaign?"
        onAccept={handleAccept}
      />
    </>
  )
}

function CardForm({
  form,
  setForm,
  labelSubmit,
  handleSubmit,
  disabledSubmit,
  readOnlyColumns = [],
}: {
  form: FormProps
  setForm: FnSetForm
  labelSubmit: string
  disabledSubmit: boolean
  handleSubmit: () => void
  readOnlyColumns?: string[]
}) {
  const formComponents = FORM_COLUMNS.map((column) => {
    const {
      key,
      type,
      values,
      description,
    } = column
    const value = form[key]
    const readOnly = column?.readOnly || readOnlyColumns.includes(key)
    let component

    if (readOnly && !value) {
      return null
    }

    switch (type) {
      case 'date':
        component = (
          <FormDate
            key={key}
            label={key}
            value={value as DateTime}
            readOnly={readOnly}
            setForm={setForm}
            description={description}
          />
        )
        break

      case 'array':
        component = (
          <FormCheckbox
            key={key}
            label={key}
            values={values as string[]}
            value={value instanceof Set ? value : undefined}
            setForm={setForm}
            description={description}
          />
        )
        break

      case 'image':
        component = (
          <FormUploader
            key={key}
            name={key}
            url={typeof value === 'string' ? value : ''}
            setForm={setForm}
            description={description}
          />
        )
        break

      case 'bool':
        component = (
          <FormSwitch
            key={key}
            label={key}
            value={value as boolean}
            setForm={setForm}
            description={description}
          />
        )
        break

      default:
        component = (
          <TextField
            key={key}
            label={key}
            value={value ?? ''}
            inputProps={{ readOnly }}
            variant={readOnly ? 'standard' : 'outlined'}
            onChange={(ev) => setForm({ [key]: ev.target.value })}
            helperText={description}
          />
        )
    }

    return component
  })

  return (
    <>
      {
        disabledSubmit
          ? <LinearProgress />
          : null
      }

      <Card>
        <CardContent>
          <Stack spacing={2}>
            {formComponents}
          </Stack>
        </CardContent>

        <CardActions>
          <Button
            variant="contained"
            color="info"
            size="large"
            disabled={disabledSubmit}
            onClick={handleSubmit}
          >
            {labelSubmit}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

function FormDate({
  label,
  value,
  readOnly,
  setForm,
  description,
}: {
  label: string
  value?: DateTime
  readOnly?: boolean
  setForm: FnSetForm
  description?: string
}) {
  const { timezone } = useContext(TimezoneContext)

  if (readOnly) {
    return (
      <TextField
        label={label}
        value={value?.isValid ? value.setZone(timezone).toFormat('yyyy/MM/dd HH:mm:ss') : null}
        inputProps={{ readOnly }}
        variant="standard"
      />
    )
  }

  return (
    <DateTimeField
      label={label}
      value={value?.isValid ? value : null}
      format="yyyy/MM/dd HH:mm"
      timezone={timezone}
      onChange={(v) => setForm({ [label]: v?.isValid ? v : null })}
      helperText={description}
    />
  )
}

function FormCheckbox({
  label,
  values,
  value = new Set<string>(),
  setForm,
  description,
}: {
  label: string
  values: string[]
  value?: Set<string>
  setForm: FnSetForm
  description?: string
}) {
  const checkboxes = values.map((v) => {
    const check = (
      <Checkbox
        value={v}
        checked={value.has(v)}
        onChange={(ev) => {
          const { target } = ev

          if (target.checked) {
            value.add(target.value)
          } else {
            value.delete(target.value)
          }

          setForm({ [label]: value })
        }}
      />
    )

    return (
      <FormControlLabel
        key={v}
        label={v}
        control={check}
      />
    )
  })

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <FormGroup row>{checkboxes}</FormGroup>
      {
        description
          ? <FormHelperText>{description}</FormHelperText>
          : null
      }
    </FormControl>
  )
}

function FormUploader({
  name,
  url,
  setForm,
  description,
}: {
  name: string
  url: string
  setForm: FnSetForm
  description?: string
}) {
  const [fileName, setFileName] = useState(url)
  const [preview, setPreview] = useState<string>(url)
  const onDrop = useCallback((files: File[]) => {
    const file = files.at(0)

    if (file) {
      setForm({ [name]: file })
      setFileName(file.name)
      setPreview(URL.createObjectURL(file))
    }
  }, [name, setForm, setPreview])
  const { isDragAccept, getRootProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    noClick: true,
  })

  useEffect(() => {
    if (url) {
      setFileName(url)
    }
  }, [url, setFileName])

  useEffect(() => (() => {
    if (preview) {
      URL.revokeObjectURL(preview)
    }
  }), [preview])

  return (
    <>
      <FormControl>
        <FormLabel>{name}</FormLabel>
        <div {...getRootProps()}>
          <TextField
            value={fileName}
            placeholder="drag and drop image files"
            color={isDragAccept ? 'info' : undefined}
            fullWidth
            focused={isDragAccept}
            helperText={description}
          />
        </div>
      </FormControl>
      {
        preview || url
          ? (
            <FormControl>
              <CardMedia
                component="img"
                image={preview || url}
                sx={{
                  width: 'fit-content',
                  height: '20vh',
                  objectFit: 'contain',
                }}
              />
            </FormControl>
          )
          : null
      }
    </>
  )
}

function FormSwitch({
  label,
  value,
  setForm,
  description,
}: {
  label: string
  value?: boolean
  setForm: FnSetForm
  description?: string
}) {
  const checkbox = (
    <Checkbox
      checked={!!value}
      onChange={(ev) => setForm({ [label]: ev.target.checked })}
    />
  )

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <FormGroup row>
        <FormControlLabel
          label=""
          control={checkbox}
        />
      </FormGroup>
      {
        description
          ? <FormHelperText>{description}</FormHelperText>
          : null
      }
    </FormControl>
  )
}

function ButtonBack() {
  const location = useLocation()
  const navigate = useNavigate()
  const handleBack = () => {
    if (location.key === 'default') {
      navigate('/campaign')
    } else {
      navigate(-1)
    }
  }

  return (
    <Button
      startIcon={<NavigateBefore />}
      onClick={() => handleBack()}
    >
      back
    </Button>
  )
}

function useForm(): [FormProps, FnSetForm] {
  const [form, setForm] = useState<FormProps>({})
  const updateForm = useCallback((newForm: FormProps) => {
    setForm((old) => ({ ...old, ...newForm }))
  }, [setForm])

  return [form, updateForm]
}

function toFormProps(data: any) {
  const props: FormProps = {}
  const json = data.config ?? {}

  FORM_COLUMNS.forEach((column) => {
    const { key, type, toJson } = column
    let value = toJson ? json[key] : data[key]

    switch (type) {
      case 'array':
        value = new Set(value)
        break

      case 'date':
        value = value ? DateTime.fromISO(value) : null
        break

      default:
    }

    if (typeof value === 'number') {
      value = value.toString()
    }

    props[key] = value
  })

  return props
}

function fromFormProps(form: FormProps) {
  const json: Record<string, unknown> = {}
  const data = new FormData()

  FORM_COLUMNS.forEach((column) => {
    const { key, type, toJson } = column
    const input = form[key]
    let value

    switch (type) {
      case 'array':
        value = []

        if (input instanceof Set) {
          input.forEach((v) => {
            value.push(v)
          })
        }
        break

      case 'date':
        if (input instanceof DateTime) {
          value = input.toISO()
        }
        break

      case 'bool':
        if (typeof input === 'boolean') {
          value = input
        } else {
          value = false
        }
        break

      default:
        if (key === 'id' && !input) {
          value = null
        } else {
          value = input ?? ''
        }
    }

    if (value instanceof File) {
      data.append(key, value)
    } else if (toJson) {
      json[key] = value
    } else if (typeof value === 'string') {
      data.append(key, value)
    }
  })

  data.append('config', JSON.stringify(json))

  return data
}

export default CampaignDetails
