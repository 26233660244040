import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useBaseTableSearchParams } from '../../hooks/useBaseTableSearchParams'

export function useUserKpiTableSearchParams(defaultInit?: URLSearchParamsInit) {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    searchValue, searchField, searchOp, pageSize, filterValue, ...filteredParams
  } = Object.fromEntries(searchParams.entries())
  const baseTableParams = useBaseTableSearchParams({
    defaultInit,
    extraParams: {
      ...filteredParams,
    },
  })

  return {
    ...baseTableParams,
    queryParams: {
      ...baseTableParams.queryParams,
    },
    clearFilters: () => {
      baseTableParams.clearFilters()
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        return newParams;
      })
    },
  }
}
