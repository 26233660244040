import {
  IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import KeyRoundedIcon from '@mui/icons-material/KeyRounded'
import React, { useState, MouseEvent } from 'react'
import secureLocalStorage from 'react-secure-storage'
import UpdateOwnPasswordModal from './UpdateOwnPasswordModal'
import { logoutAdmin } from '../../services/fetch'

interface Props {
  userName: string
  userRole: string
}

function AccountSettingButton(props: Props) {
  const navigate = useNavigate()
  const { userName, userRole } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showUpdateOwnPasswordModal, setShowUpdateOwnPasswordModal] = useState(false)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseUpdateOwnPasswordModal = (actionTriggered: boolean) => {
    setShowUpdateOwnPasswordModal(false)
  }

  const handleOpenUpdateOwnPasswordModal = () => {
    setShowUpdateOwnPasswordModal(true)
  }

  const onLogOut = () => {
    secureLocalStorage.clear()
    logoutAdmin().then(() => {
      navigate('/login')
    })
  }

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <ManageAccountsIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography variant="h6" sx={{ mx: 4 }}>
          {userName}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mx: 4 }}>
          {userRole}
        </Typography>
        <MenuItem onClick={handleOpenUpdateOwnPasswordModal}>
          <ListItemIcon>
            <KeyRoundedIcon />
          </ListItemIcon>
          <ListItemText>Change Password</ListItemText>
        </MenuItem>
        <MenuItem onClick={onLogOut}>
          <ListItemIcon>
            <LogoutRoundedIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
      <UpdateOwnPasswordModal
        open={showUpdateOwnPasswordModal}
        handleClose={handleCloseUpdateOwnPasswordModal}
        userName={userName}
      />
    </>
  )
}

export default AccountSettingButton
