import { checkAuth } from './authentication'
import { buildApiUrl, UrlQueries } from './urlBuilder'

interface FetchAdminParams {
  path: string;
  method: 'GET' | 'PUT' | 'POST' | 'DELETE';
  queries?: UrlQueries;
  body?: Record<string, any>;
}

const getHeaders = (method: string): HeadersInit => {
  const headers: HeadersInit = {}

  if (method !== 'GET') {
    headers.Accept = 'application/json, text/plain, */*';
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};

const getRequestInit = (method: string, body: Record<string, any> | FormData = {}): RequestInit => {
  let headers: HeadersInit = getHeaders(method);

  if (method !== 'GET' && body instanceof FormData) {
    if (headers instanceof Headers) {
      headers.delete('Content-Type');
    } else if (typeof headers === 'object') {
      headers = Object.fromEntries(
        Object.entries(headers).filter(([key]) => key.toLowerCase() !== 'content-type'),
      );
    }
  }

  const init: RequestInit = {
    credentials: 'include',
    method,
    headers,
  };

  if (method !== 'GET') {
    if (body instanceof FormData) {
      init.body = body;
    } else {
      init.body = JSON.stringify(body);
    }
  }

  return init;
};

export async function fetchAdmin({
  path,
  method,
  queries = {},
  body = {},
}: FetchAdminParams): Promise<Response> {
  const url = buildApiUrl({ path, queries });
  const init = getRequestInit(method, body);
  const res = await fetch(url, init);
  return checkAuth(res);
}

interface FetchAdminSessionParams {
  userName: string;
  password: string;
}

export async function fetchAdminSession({
  userName,
  password,
}: FetchAdminSessionParams): Promise<Response> {
  const url = buildApiUrl({ path: 'sessions', queries: {} });

  const init: RequestInit = {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userName, password }),
  };

  return fetch(url, init);
}

export async function logoutAdmin(): Promise<void> {
  const url = buildApiUrl({ path: 'sessions-logout', queries: {} })
  const init: RequestInit = {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  }
  try {
    await fetch(url, init)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error logging out:', error)
  }
}
