import { useState, useEffect, useCallback } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid'
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { fetchData } from './KpiDBPersonalizeDau'
import { initFormData } from '../../constants/kpiConstants'

interface FormData {
  from: moment.Moment | null
  to: moment.Moment | null
  timezone: string
  storeCountryCode: string
}

type UpdateFormData = Partial<FormData> & {
  columnVisibility?: Record<string, boolean>
}

export function SearchFormDau(props: { updateStatus: (obj: object) => void }) {
  const { updateStatus } = props
  const { daysAfter, ...filteredQueryParams } = initFormData // dau not need daysAfter
  const [formData, setFormData] = useState<FormData>(filteredQueryParams)
  const [isDataFetched, setIsDataFetched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const updateFormData = (newFormData: UpdateFormData) => {
    setFormData((old) => ({ ...old, ...newFormData }))
    if (newFormData.columnVisibility) {
      updateStatus({ columnVisibility: newFormData.columnVisibility })
      return
    }
    setIsDataFetched(false)
  }

  const fetchAndUpdateData = useCallback(async () => {
    if (isDataFetched || isLoading) return
    setIsLoading(true)
    updateStatus({ loading: true })

    try {
      const { columns, rows } = await fetchData({ formData, navigate })
      updateStatus({
        columns,
        rows,
        loading: false,
      });

      setIsDataFetched(true)
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    } finally {
      setIsLoading(false)
    }
  }, [formData, updateStatus, isDataFetched, isLoading, navigate])

  useEffect(() => {
    if (
      formData.from
      && formData.to
      && formData.storeCountryCode
      && formData.timezone
      && !isDataFetched
    ) {
      const delayFetch = setTimeout(() => {
        fetchAndUpdateData()
      }, 500)

      return () => clearTimeout(delayFetch)
    }

    return undefined
  }, [formData, fetchAndUpdateData, isDataFetched])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box padding={1}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="From"
              value={formData.from}
              onChange={(value) => updateFormData({ from: value })}
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  sx: { maxWidth: 170 },
                },
              }}
            />
            <DatePicker
              label="To"
              value={formData.to}
              onChange={(value) => updateFormData({ to: value })}
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  sx: { maxWidth: 170 },
                },
              }}
            />
            <FormControl sx={{ minWidth: 140 }}>
              <InputLabel id="kpi-country-select-label">Country</InputLabel>
              <Select
                labelId="kpi-country-select-label"
                id="kpi-country-select"
                value={formData.storeCountryCode}
                label="Country"
                onChange={(event) => updateFormData({ storeCountryCode: event.target.value })}
              >
                <MenuItem value="">ALL</MenuItem>
                <MenuItem value="USA_CAN">USA & CAN</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="CAN">CAN</MenuItem>
                <MenuItem value="JPN">JPN</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="kpi-timezone-select-label">Timezone</InputLabel>
              <Select
                labelId="kpi-timezone-select-label"
                id="kpi-timezone-select"
                value={formData.timezone}
                label="Timezone"
                onChange={(event) => updateFormData({ timezone: event.target.value })}
              >
                <MenuItem value="UTC">UTC</MenuItem>
                <MenuItem value="America/Los_Angeles">PT</MenuItem>
                <MenuItem value="Asia/Tokyo">JST</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Box marginTop={1} marginBottom={2}>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: 'kpiDau',
                }}
                printOptions={{
                  disableToolbarButton: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  )
}
