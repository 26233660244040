import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { DIALOG_TYPES } from '../../../../constants'
import { FilterProps } from '../types'

function CustomTypeFilter({ field, filter, setFilter }: FilterProps) {
  const handleSelectChange = (event: SelectChangeEvent) => {
    setFilter({
      ...filter,
      filterField: field,
      filterValue: event.target.value,
    })
  }

  return (
    <Select
      value={filter.filterValue || ''}
      onChange={handleSelectChange}
      variant="standard"
      displayEmpty
      fullWidth
    >
      {Object.entries(DIALOG_TYPES).map(([key, value]) => (
        <MenuItem
          key={key}
          value={key}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {value}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CustomTypeFilter
